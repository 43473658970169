<template>
  <span v-if="questionCount">{{
    `${startAdornment}${questionCount}${endAdornment}`
  }}</span>
</template>

<script>
import { MoneyCalculator } from "@ivy-way/material";
import toeflApi from "@/apis/toefl.js";

export default {
  props: {
    startAdornment: {
      type: String,
      default: ""
    },
    endAdornment: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      questionCount: ""
    };
  },
  async created() {
    const { toefl_question_count } = await toeflApi.getQuestionCount();
    this.questionCount = MoneyCalculator.addThousandsComma(
      `${Math.floor(toefl_question_count / 100)}00`
    );
  }
};
</script>
